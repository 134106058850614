<template>
  <div class="timer-container">
    <svg class="progress-circle" viewBox="0 0 36 36">
      <path
        class="circle-bg"
        d="M18 2.0845
           a 15.9155 15.9155 0 0 1 0 31.831
           a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <path
        class="circle"
        :class="circleColor"
        :stroke-dasharray="progress + ', 100'"
        d="M18 2.0845
           a 15.9155 15.9155 0 0 1 0 31.831
           a 15.9155 15.9155 0 0 1 0 -31.831"
      />
    </svg>
    <span :class="['timer-text', { pulse: timeLeft <= 3 }]">{{
      timeLeft
    }}</span>
  </div>
</template>

<script>
export default {
  props: {
    maxTime: Number,
  },
  data() {
    return {
      timeLeft: this.maxTime,
    };
  },
  computed: {
    progress() {
      return (this.timeLeft / this.maxTime) * 100;
    },
    circleColor() {
      if (this.timeLeft > 4) {
        return "green";
      } else if (this.timeLeft === 4) {
        return "yellow";
      } else if (this.timeLeft <= 3) {
        return "red pulse";
      } else {
        return "red";
      }
    },
  },
  mounted() {
    this.startTimer();
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        if (this.timeLeft > 0) {
          this.timeLeft--;
        } else {
          clearInterval(this.timer);
          this.$emit("time-up");
        }
      }, 1000);
    },
    resetTimer() {
      clearInterval(this.timer);
      this.timeLeft = this.maxTime;
      this.startTimer();
    },
  },
};
</script>

<style scoped>
.timer-container {
  position: relative;
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
  text-align: center;
}

.progress-circle {
  width: 100%;
  height: 100%;
}

.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 3.5;
}

.circle {
  fill: none;
  stroke-width: 3.5;
  stroke-linecap: round;
  transition: stroke-dasharray 0.3s ease, stroke 0.3s ease;
}

/* Colors for different states */
.green {
  stroke: #4caf50; /* Muted green */
}

.yellow {
  stroke: #ffeb3b; /* Muted yellow */
}

.red {
  stroke: #f44336; /* Muted red */
}

/* Pulsing effect */
.pulse {
  animation: pulse-animation 0.25s infinite;
}

@keyframes pulse-animation {
  0% {
    stroke-width: 2.8;
  }
  50% {
    stroke-width: 4;
  }
  100% {
    stroke-width: 2.8;
  }
}

.timer-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #333;
  font-weight: bold;
  font-size: 2rem;
  transition: transform 0.25s ease;
}

.timer-text.pulse {
  animation: text-pulse-animation 1s infinite;
}

@keyframes text-pulse-animation {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.5);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}
</style>
